import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Accordion, Tab, Tabs, Col } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { AshTrace } from '../../../modules/ash/components/ash-trace';

const AshGuidesBossFloat: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Boss Challenge: Clash of Floats (300%)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_float.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Boss Challenge: Clash of Floats (300%)</h1>
          <h2>
            A dedicated guide for the Boss Challenge: Clash of Floats in Ash
            Echoes. Beat the 300% difficulty with ease with our guide!
          </h2>
          <p>
            Last updated: <strong>30/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                For the generic Tomorrow is a Blooming Day event guide, go here
                as in this guide we will only focus on the Boss Challenge: Clash
                of Floats part of the event.
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Tomorrow is a Blooming Day"
                  link="/ash-echoes/guides/tomorrow-is-a-blooming-day"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_bloom.webp"
                      alt="Tomorrow is a Blooming Day"
                    />
                  }
                  isNew
                />
              </Row>
              <SectionHeader title="Boss Challenge: Clash of Floats" />
              <StaticImage
                src="../../../images/ash/event/float_1.webp"
                alt="Guides"
              />
              <p>
                Unlocked by clearing the first chapter of the event story, and
                the higher difficulties are progressively unlocked as you play
                through the event story.
              </p>
              <p>
                The Clash of Floats is unique, having you fight multiple
                vehicles called Floats one after the other, with your Terminal
                being replaced by your own team’s Float, the Fortunia.
              </p>
              <p>
                In this guide we’re going to cover the 300% difficulty, which
                shouldn’t pose too much of a threat to you as long as you keep a
                few key mechanics and advice in mind.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Pre-Fight Preparation" />
              <p>
                For this fight, an Engraving power of about A28-S30 is more than
                enough, and by playing around the mechanics properly the massive
                DR of the Bosses can be reduced heavily and their nukes can be
                interrupted or dodged.
              </p>
              <p>
                Before diving into each of the Floats’ main gimmicks, a couple
                things can generally help you when preparing yourself:
              </p>
              <ul className="bigger-margin">
                <li>
                  Each of the Float’s main gimmicks can be countered by the
                  corresponding Terminal Skill, and all of the Bosses are
                  capable of very high damage so try to always pick up the gifts
                  to be ready.
                </li>
                <li>
                  Make sure to always bring a ranged Echomancer or two to pick
                  up the gifts more easily.
                </li>
                <li>
                  A Tank is nice, but if you play multiple Strikers it isn’t
                  necessary.
                </li>
              </ul>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Key Mechanics" />
              <p>
                In this section we take a look at all the mechanics you need to
                keep an eye out for and how to counter them!{' '}
              </p>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>The Lulu Xpress</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      The Lulu Xpress possesses high Burst, mostly in the form
                      of a charge and a flurry of projectiles during its second
                      phase. Lastly, if it reaches its target it can perform an
                      extremely fast and brutal 360 sweep that can instantly
                      take down your squishy units.
                    </p>
                    <p>
                      To counter its DR mechanic and interrupt its attacks, the
                      [Ultimate Fortune] Skill can be used.
                    </p>
                    <p>
                      Take the time to collect gifts and charge up your Terminal
                      before it reaches mid HP, quickly kill the extra mobs
                      during interphase to prevent it from healing (but don’t
                      waste your Terminal Skills for that!), then prepare your
                      burst to clear both its remaining HP and start bursting
                      down Fortune Call.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>The Fortune Call</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Unlike the Lulu Xpress, Fortune Call is immobile for the
                      whole fight and its DR mechanic is in the form of a very
                      high strength Barrier, which can either be broken with the
                      [Buck Parade] Skill or with enough projectiles (such as
                      Scarlett’s Skill 2).
                    </p>
                    <p>
                      It is advised to quickly burst down the Fortune Call as
                      its damage ramps up over time as it summons more helpers,
                      and its most annoying attack is the Time Bombs it places
                      on 2 of your Echomancers.
                    </p>
                    <p>To counter this Skill, you can either:</p>
                    <ul>
                      <li>Burst down the Float before it gets to use it;</li>
                      <li>
                        Tag out one of your targeted Echomancers and swap in
                        <AshCharacter
                          slug="su-xiao"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        to protect from a potential one shot;
                      </li>
                      <li>
                        Or if you have enough Defensive stats simply keep both
                        targeted Echomancers away from any other allies to
                        minimize the damage.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>The Alpha No.1</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Final Float standing in your way, the Alpha No.1 joins the
                      battle in flight, so it’s impossible to hit it with Ground
                      units until you knock it down. In the same way, if you
                      want to clear the bonus condition of ending the fight with
                      our Terminal’s carpet bombing, it’s better to use it when
                      there’s about 25% of the final HP bar left. As you’ll see
                      in the videos, it’s very easy to miss by having way too
                      much damage with your Echomancers.
                    </p>
                    <p>
                      The Alpha No.1 travels all the way to your Terminal,
                      hovering above it and after a few seconds locks on a
                      target, moving to it and then dealing constant damage in
                      AoE. If the target unit is close to the Terminal it also
                      damages it, so be careful!
                    </p>
                    <p>
                      The most important part of this final phase is to destroy
                      the three Hot Air Balloons that keep the Boss up in the
                      sky, since it not only allows your Ground attackers to
                      deal damage but also controls the Boss for a long
                      duration.
                    </p>
                    <p>For that, you can choose to:</p>
                    <ul>
                      <li>
                        Use the [Twin Koi Blaster] Terminal Skill, easily
                        knocking down 2/3 of them;
                      </li>
                      <li>
                        Have your Ranged Echomancer(s) placed away from the Boss
                        so that they target the Balloons;
                      </li>
                      <li>
                        Or completely ignore the mechanic if you’re playing with
                        multiple Ranged Dealers or a strong single Ranged Carry
                        and focus on quickly defeating the Boss instead.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Team Examples" />
              <p>
                Let’s go over a few teams. They have varying levels of Engraving
                Stats and Skills but all are capable of beating the Boss.
              </p>
              <p>
                <strong>Note!</strong> Use the TABS below to check different
                teams.
              </p>
              <Tabs
                defaultActiveKey="Team1"
                transition={false}
                id="event-teams"
                className="nav-tabs"
              >
                <Tab
                  eventKey="Team1"
                  title={<>Team #1 - The Wind Team</>}
                  className="with-bg"
                >
                  <h5>Team #1 - The Wind Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/float_2.webp"
                    alt="Guides"
                  />
                  <p>
                    Sporting very high damage even just after a few seconds of
                    fighting, this build is all about making{' '}
                    <AshCharacter
                      slug="scarlett"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    shine.
                  </p>
                  <p>
                    <AshTrace name="Going Off-Course" mode="data" onProfile />{' '}
                    isn’t mandatory but works great nonetheless, and the Combo
                    Mastery from{' '}
                    <AshTrace name="Secret Melody" mode="data" onProfile /> can
                    be stacked insanely fast with just Scarlett and{' '}
                    <AshCharacter
                      slug="di-yan"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    The combo of{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> and{' '}
                    <AshTrace
                      name="Blood Ruvia - Secret"
                      mode="data"
                      onProfile
                    />{' '}
                    is the main source of Burst when paired with Scarlett’s
                    Skill 2, but currently only{' '}
                    <AshCharacter
                      slug="shiratori-azusa"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    can activate this combo.
                  </p>
                  <p>
                    If you do not have her and can’t get her from assists, you
                    are better off using generic Traces that improve Triangle
                    units like{' '}
                    <AshTrace
                      name="Beneath The Deception"
                      mode="data"
                      onProfile
                    />{' '}
                    or{' '}
                    <AshTrace
                      name="Curiosity Of A Hailinian"
                      mode="data"
                      onProfile
                    />{' '}
                    . Alternatively,{' '}
                    <AshTrace
                      name="Behind The Curtains"
                      mode="data"
                      onProfile
                    />{' '}
                    also works for a more defensive playstyle.
                  </p>
                  <p>
                    <AshTrace
                      name="Shadows Of The Alley"
                      mode="data"
                      onProfile
                    />{' '}
                    can be changed to{' '}
                    <AshTrace name="Born From Ashes" mode="data" onProfile />{' '}
                    for faster Stacking, but if you decide to run the first part
                    of the fight without Supports you won’t benefit from it.
                  </p>
                  <p>
                    Here’s the link to the more comprehensive guide about this
                    Scarlett centric team:{' '}
                  </p>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    <CategoryCard
                      title="Should You Pull Scarlett?"
                      link="/ash-echoes/guides/should-you-pull-scarlett"
                      image={
                        <StaticImage
                          src="../../../images/ash/categories/category_scar.webp"
                          alt="Should You Pull Scarlett?"
                        />
                      }
                      isNew
                    />
                  </Row>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="" />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Team2"
                  title={<>Team #2 - The Fire Team</>}
                  className="with-bg"
                >
                  <h5>Team #2 - The Fire Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/float_3.webp"
                    alt="Guides"
                  />
                  <p>
                    A Fairly expensive and slow starting build, this Fire Team
                    is extremely safe and sees a big ramp up in damage as the
                    fight goes on and all conditions are met. Thanks to the
                    naturally high defensive stats and the various Defensive
                    Skills (both from Traces and from the Echomancers) you can
                    slot in more Damage dealers or buffers, even though we still
                    brought{' '}
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    with us just to be extra safe.
                  </p>
                  <p>
                    While we can’t abuse{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> Main
                    Skill with this setup, its ability to synergize with Fire
                    units is still awesome, but you can ultimately replace it
                    for either{' '}
                    <AshTrace
                      name="Behind The Curtains"
                      mode="data"
                      onProfile
                    />{' '}
                    or <AshTrace name="Managing Marks" mode="data" onProfile />,
                    or <AshTrace name="Festive Night" mode="data" onProfile />,
                    although it doesn’t bring much to the team aside from
                    buffing{' '}
                    <AshCharacter
                      slug="baili-tusu"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    and making the team even safer than it already is (which
                    shouldn’t be needed in this fight).
                  </p>
                  <p>
                    Here’s the link to the full review of Baili Tusu and the
                    various builds you can go with:
                  </p>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    <CategoryCard
                      title="Should You Pull Baili Tusu?"
                      link="/ash-echoes/guides/should-you-pull-baili-tusu"
                      image={
                        <StaticImage
                          src="../../../images/ash/categories/category_baili.webp"
                          alt="Should You Pull Baili Tusu?"
                        />
                      }
                      isNew
                    />
                  </Row>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="" />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Team3"
                  title={<>Team #3 - The Summoner Team</>}
                  className="with-bg"
                >
                  <h5>Team #3 - The Summoner Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/float_4.webp"
                    alt="Guides"
                  />
                  <p>
                    A classic take on the Summoner Team, the low VIT can be
                    problematic if you don’t dodge, as most of the attacks are
                    going to knock you out in one or two hits. Still, if you
                    manage to position yourself correctly the damage is
                    excellent and all 3 phases of the Fight can be cleared very
                    very quickly.
                  </p>
                  <p>
                    All of the Traces are either Free SSRs, SR or assists, for a
                    cheap build. You can definitely replace{' '}
                    <AshTrace
                      name="Memories Of The Past"
                      mode="data"
                      onProfile
                    />
                    with{' '}
                    <AshTrace
                      name="Behind The Curtains"
                      mode="data"
                      onProfile
                    />{' '}
                    for an easier time.
                  </p>
                  <p>
                    PS: You can aim{' '}
                    <AshCharacter
                      slug="cen-ying"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    Ultimate towards the center of the Top of the arena to hit
                    both the Lulu Xpress when it comes back and deal massive
                    damage to Fortune Call when it comes in.
                  </p>
                  <p>Here’s the link to the Summoner team:</p>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    <CategoryCard
                      title="Should You Pull Cen Ying?"
                      link="/ash-echoes/guides/should-you-pull-cen-ying"
                      image={
                        <StaticImage
                          src="../../../images/ash/categories/category_cen.webp"
                          alt="Should You Pull Cen Ying?"
                        />
                      }
                    />
                  </Row>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="" />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Team4"
                  title={<>Team #4 - The Corrosion Team</>}
                  className="with-bg"
                >
                  <h5>Team #4 - The Corrosion Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/float_5.webp"
                    alt="Guides"
                  />
                  <p>
                    Simple Corrosion team,{' '}
                    <AshCharacter
                      slug="scarlett"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    can comfortably be replaced with{' '}
                    <AshCharacter
                      slug="sephonis"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    to trigger the Combo Mastery Skill alongside
                    <AshCharacter
                      slug="gina"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />
                    .
                  </p>
                  <p>
                    For the Traces,{' '}
                    <AshTrace name="Dusk Radiance" mode="data" onProfile /> is
                    amazing but only with a D3+
                    <AshCharacter
                      slug="lorelle"
                      mode="inline"
                      showLabel
                      showTags
                      showIcon
                      enablePopover
                    />{' '}
                    , although any other VIT Trace can do the trick.
                  </p>
                  <p>
                    <AshTrace
                      name="Memories Of The Past"
                      mode="data"
                      onProfile
                    />
                    , the first TRM Trace, is obtained after clearing Chapter 4
                    of the Main Story and is another great free Trace.
                  </p>
                  <p>
                    And once again, you can find more about the Corrosion team
                    and its different setups there:
                  </p>
                  <Row
                    xs={1}
                    md={2}
                    lg={2}
                    xxl={2}
                    className="g-3 category-cards-container"
                  >
                    <CategoryCard
                      title="Should You Pull Lorelle?"
                      link="/ash-echoes/guides/should-you-pull-lorelle"
                      image={
                        <StaticImage
                          src="../../../images/ash/categories/category_lorelle.webp"
                          alt="Should You Pull Lorelle?"
                        />
                      }
                    />
                  </Row>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="" />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Pre-Fight Preparation</a>
                </li>
                <li>
                  <a href="#section-2">Key Mechanics</a>
                </li>
                <li>
                  <a href="#section-3">300% Team Examples</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesBossFloat;

export const Head: React.FC = () => (
  <Seo
    title="Boss Challenge: Clash of Floats (300%) | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for the Boss Challenge: Clash of Floats in Ash Echoes. Beat the 300% difficulty with ease with our guide!"
    game="ash"
  />
);
